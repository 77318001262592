import React from "react";

import { PageHeader } from "../components/PageHeader";
import { ContactForm } from "../components/ContactForm";

const Contact: React.FC = () => (
  <section>
    <PageHeader
      title="Contact Us"
      content={
        <>
          <p>
            Eleven-I are based in Glossop near Manchester in the UK. Please use
            the form below to contact us, we're always happy to help!
          </p>
          <p>Send Us an Email to: <a href="mailto:Contact@eleven-i.com">Contact@eleven-i.com</a></p>
        </>
      }
    />
     <ContactForm />
  </section>
);

export { Contact };
