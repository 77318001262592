import React, { useEffect, useState } from "react";

import billJpg from "../img/team/bill/bill.jpg";
import billAvif from "../img/team/bill/bill.avif";
/*
import chrisJpg from "../img/team/chris/chris.jpg";
import chrisAvif from "../img/team/chris/chris.avif";
*/
import richAvif from "../img/team/rich/rich.avif";
import richJpg from "../img/team/rich/rich.jpg";

import jonathanJpg from "../img/team/jonathan/jonathan.jpg";
import jonathanAvif from "../img/team/jonathan/jonathan.avif";

import philJpg from "../img/team/phil/phil.jpg";
import philAvif from "../img/team/phil/phil.avif";

import francisAvif from "../img/team/francis/francis.avif";
import francisJpg from "../img/team/francis/francis.jpg";

import jackAvif from "../img/team/jack/jack.avif";
import jackJpg from "../img/team/jack/jack.jpg";

import tomJpg from "../img/team/tom/tom.jpg";
import tomAvif from "../img/team/tom/tom.avif";

import { PageHeader } from "../components/PageHeader";
import { PageContent } from "../components/PageContent";

const Team: React.FC = () => {
  const [imageWidth, setImageWidth] = useState(4);

  const updateImageWidth = () => {
    const newWidth = Math.ceil(6 - (window.innerWidth - 780) / 90);
    setImageWidth(newWidth < 2 ? 2 : newWidth > 6 ? 6 : newWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", updateImageWidth);
    updateImageWidth();
  }, []);

  return (
    <section>
      <PageHeader title="The Eleven-I Team" />
      <PageContent
        imageWidth={imageWidth}
        content={[
          {
            name: "CEO: Bill Slatter",
            text: "Bill has 14 years of experience in running successful businesses, with a focus on smart systems and automation for blue chip companies including Ford Europe. Bill has established a relationship with GE Renewables and has provided them with bespoke condition monitoring solutions since 2015.",
            image: (
              <picture>
                <source srcSet={billAvif} type="image/avif" />
                <img
                  src={billJpg}
                  className="card-img"
                  alt="CEO: Bill Slatter"
                />
              </picture> 
            ),
          },
          {
            name: "NED: Rich Furniss",
            text: (
              <>
                <p>
                  15+ years wind industry experience including 9 with major OEM
                </p>
                <p>
                </p>
              </>
            ),
            image: (
              <picture>
                <source srcSet={richAvif} type="image/avif" />
                <img
                  src={richJpg}
                  className="card-img"
                  alt="NED: Rich Furniss"
                />
              </picture> 
            ),
          },
          {
            name: "Chairman: Jonathan Guthrie",
            text: (
              <>
                <p>
                  A Chartered Accountant, Jonathan spent time with Ernst &amp;
                  Young and BSkyB before becoming an entrepreneur.
                </p>
                <p>
                  Founder of Paywizard Group (formerly MGt plc), a Saas CRM and
                  billing platform for the media and pay-TV industry. He is
                  Chairman of Altair Medical, as well as NED of Paywizard and My
                  Simple Mortgage.
                </p>
              </>
            ),
            image: (
              <picture>
                <source srcSet={jonathanAvif} type="image/avif" />
                <img
                  src={jonathanJpg}
                  className="card-img"
                  alt="Chairman: Jonathan Guthrie"
                />
              </picture>
            ),
          },
          {
            name: "CTO: Dr Phil Shackleton",
            text: (
              <>
                <p>
                  As a first class graduate in mechanical engineering, Phil went
                  on to complete a PhD focused on the computer modelling of the
                  wheel-rail contact for dynamic simulation of rail vehicles.
                </p>
                <p>
                  Through working on a wide range of research and commercial
                  projects he has continued to become an expert in the
                  application of numerical methods to analyse, and provide
                  solutions to, real-world engineering problems. His work has
                  helped customers achieve system level optimisation, support
                  step changes to sector-wide operational safety, and directly
                  influenced standards change.
                </p>
              </>
            ),
            image: (
              <picture>
                <source srcSet={philAvif} type="image/avif" />
                <img
                  src={philJpg}
                  className="card-img"
                  alt="Dynamics Engineer &amp; Data Analyst: Dr Phil Shackleton"
                />
              </picture> 
            ),
          },
          {
            name: "Data Scientist: Dr Francis Bench",
            text: (
              <>
                <p>
                  After being awarded first-class degree in Physics, Francis
                  went on to complete a PhD in particle physics, specialising in
                  statistical analyses of neutrino oscillations. During his
                  studies, Francis gained a wealth programming experience with
                  C++ and Python.
                </p>
                <p>
                  At Eleven-I, Francis has worked on vibrational analyses of
                  wind turbine blades to validate their behaviour for General
                  Electric.
                </p>
              </>
            ),
            image: (
              <picture>
                <source srcSet={francisAvif} type="image/avif" />
                <img
                  src={francisJpg}
                  className="card-img"
                  alt="Data Scientist: Dr Francis Bench"
                />
              </picture>
            ),
          },
/*
          {
            name: "IT: Jack Brierley",
            text: (
              <>
                <p>
                  Jack is an undergraduate aiming to become a network engineer.
                  Jack has experience with consumer and enterprise networking
                  gear, servers and has a good general knowledge of computers,
                  technology. Jack is our resident PAT Tester and manages our
                  internal NAS server as well as being a very practical extra
                  set of hands.
                </p>
              </>
            ),
            image: (
              <picture>
                <source srcSet={jackAvif} type="image/avif" />
                <img
                  src={jackJpg}
                  className="card-img"
                  alt="IT Technician: Jack Brierley"
                />
              </picture>
            ),
          },
          {
            name: "Consultant: Tom Young",
            text: (
              <>
                <p>
                  Tom is a Chartered Engineer with twenty years of industry
                  experience and a first class Engineering degree from
                  Cambridge. He is a specialist in control, measurement and data
                  analysis systems.
                </p>
                <p>
                  Tom previously ran the control and SCADA team at an
                  award-winning wind turbine startup, where he developed an
                  innovative vibration monitoring system. He also spent several
                  years in the &apos;Advanced Information Processing&apos;
                  research unit at BAE SYSTEMS.
                </p>
              </>
            ),
            image: (
              <picture>
              <source srcSet={tomAvif} type="image/avif" />
              <img
                src={tomJpg}
                className="card-img"
                alt="Software Engineer &amp; Data Analyst: Tom Young"
              />
              </picture>
            ),
          },*/
        ]}
      />
    </section>
  );
};

export { Team };
