import React, { useEffect, useState } from "react";

import { TriangleDown } from "../components/svg/TriangleDown";
import { PageStart } from "../components/PageStart";
import { SplashIcons } from "../components/SplashIcons";
import { Fade } from "../components/svg/Fade";
import { Sensor } from "../components/svg/Sensor";
import { Ai } from "../components/svg/Ai";
import { Money } from "../components/svg/Money";
import { BigData } from "../components/svg/BigData";
import { TriangleLeftFlowchart } from "../components/svg/TriangleLeftFlowchart";
import { TriangleMiddleFlowchart } from "../components/svg/TriangleMiddleFlowchart";
import { TriangleRightFlowchart } from "../components/svg/TriangleRightFlowchart";
import { CoverPageNav } from "../components/CoverPageNav";
import ElevenISensors from "../img/11i-sensors.jpg";
import FrequencySpectrum from "../img/frequency-spectrum-for-nominal-and-fault-cases.png";
import StatisticalOperational from "../img/statistical-operational-modal-analysis.png";

import headerLogo from "../img/header-logo-min.svg";
import { FlowchartStartVertical } from "../components/svg/FlowchartStartVertical";
import { FlowchartMiddleVertical } from "../components/svg/FlowchartMiddleVertical";
import { FlowGrid } from "../components/FlowGrid";
import { ContactForm } from "../components/ContactForm";

import { sunsetTurbine } from "../img/sunset-turbine/SunsetTurbine";

const Home: React.FC = () => {
  const [aspectRatio, setAspectRatio] = useState("wide-screen");
  const [left, setLeft] = useState(0);

  const updateAspectRatio = () => {
    const canvas = document.getElementById(
      "hero-lightpass"
    ) as HTMLCanvasElement;
    const imageRatio = canvas.width / canvas.height;
    const windowRatio = window.innerWidth / window.innerHeight;
    const newAspectRatio =
      imageRatio > windowRatio ? "narrow-screen" : "wide-screen";
    setAspectRatio(newAspectRatio);
    setLeft(
      newAspectRatio === "narrow-screen"
        ? (window.innerWidth -
          (canvas.width * window.innerHeight) / canvas.height) /
        2
        : 0
    );
  };

  useEffect(() => {
    const html = document.documentElement;
    const canvas = document.getElementById(
      "hero-lightpass"
    ) as HTMLCanvasElement;
    const context = canvas.getContext("2d") as CanvasRenderingContext2D;

    const frameCount = 12;

    const img = new Image();
    img.src = sunsetTurbine(0);
    canvas.width = 1920;
    canvas.height = 1088;
    img.onload = function () {
      context.drawImage(img as CanvasImageSource, 0, 0);
    };

    const updateImage = (index: number) => {
      const img = new Image();
      img.src = sunsetTurbine(index);
      context.drawImage(img as CanvasImageSource, 0, 0);
    };

    window.addEventListener("scroll", () => {
      const scrollTop = html.scrollTop;
      const maxScrollTop = window.innerHeight * 1.6;
      const scrollFraction = scrollTop / maxScrollTop;
      const frameIndex = Math.min(
        frameCount - 1,
        Math.ceil(scrollFraction * frameCount)
      );
      requestAnimationFrame(() => updateImage(frameIndex + 1));
    });

    window.addEventListener("resize", updateAspectRatio);
    updateAspectRatio();
  }, []);

  return (
    <div className="page">
      <CoverPageNav />
      <main>
        <section id="section-0">
          <div className="section-content cover-page">
            <img
              src={headerLogo}
              height="50"
              alt="Eleven-I"
              loading="lazy"
              className="cover-logo"
            />
            <h1 className="home-title">Bespoke Asset Monitoring</h1>
            <SplashIcons />
            <Fade name="Out" />
          </div>
        </section>
        <div className="canvas-container">
          <TriangleDown name="arrow-down-page-break" />
          <canvas
            style={{ left: left }}
            className={`canvas ${aspectRatio}`}
            id="hero-lightpass"
          />
          <PageStart />
        </div>
        <section className="section">
          <Fade name="In" />
          <div className="section-content">
            <h1>What We Do</h1>
            <div className="flowchart">
              <div className="arrow-container">
                <TriangleLeftFlowchart name="first horizontal" />
                <FlowchartStartVertical name="first vertical" />
              </div>
              <div className="hasIcon first">
                <h4>1</h4>
                <Sensor />
                <h4>Sensors to Collect Data</h4>
              </div>
              <div className="arrow-container">
                <TriangleMiddleFlowchart name="second horizontal" />
                <FlowchartMiddleVertical name="second vertical" />
              </div>
              <div className="hasIcon second">
                <h4>2</h4>
                <Ai />
                <h4>Engineering Understanding &amp; Machine Learning</h4>
              </div>
              <div className="arrow-container">
                <TriangleMiddleFlowchart name="third horizontal" />
                <FlowchartMiddleVertical name="third vertical" />
              </div>
              <div className="hasIcon third">
                <h4>3</h4>
                <BigData />
                <h4>Information for Preventative Maintenance</h4>
              </div>
              <div className="arrow-container">
                <TriangleMiddleFlowchart name="fourth horizontal" />
                <FlowchartMiddleVertical name="fourth vertical" />
              </div>
              <div className="hasIcon fourth">
                <h4>4</h4>
                <Money />
                <h4>Reduce Operations &amp; Maintenance costs</h4>
              </div>
              <div className="arrow-container">
                <TriangleRightFlowchart name="end horizontal" />
                <TriangleDown name="end vertical" />
              </div>
            </div>
          </div>
          <Fade name="Out" />
        </section>
        <div className="background-image background-2">
          <TriangleDown name="arrow-down-page-break" />
          <PageStart />
        </div>
        <section className="section" id="section-1">
          <Fade name="In" />
          <div className="section-content">
            <h1>Our Sensors</h1>
            <h5>
              We offer a range of sensors for data capture including
              accelerometers, strain gauges and GPS.
            </h5>
            <ul className="no-list-style">
              <li>
                <h5>High specification digital accelerometers</h5>
              </li>
              <li>
                <h5>Tempory or permanent installation</h5>
              </li>
              <li>
                <h5>Wired RS485 and wireless radio</h5>
              </li>
              <li>
                <h5>Versitile and expandable</h5>
              </li>
              <li>
                <h5>SD and local storage</h5>
              </li>
              <li>
                <h5>No calibration</h5>
              </li>
              <li>
                <h5>Low cost</h5>
              </li>
            </ul>
            <img
              src={ElevenISensors}
              alt=""
              className="clipping-sensors-diagonal"
            />
          </div>
          <Fade name="Out" />
        </section>
        <div className="background-image background-3">
          <TriangleDown name="arrow-down-page-break" />
          <PageStart />
        </div>
        <section className="section" id="section-2">
          <Fade name="In" />
          <div className="section-content">
            <h1>Engineering &amp; ML understanding</h1>
            <div>
              <h4>
                Damage detection and changes to structural characteristics
              </h4>
              <ul>
                <li>
                  Comparative changes by type or over time (supported by machine
                  learning)
                </li>
                <li>Event detection (e.g. bird strikes, abnormal movement)</li>
                <li>
                  Frequency response and transmissibility (defect detection such
                  as bond failure)
                </li>
                <img
                  src={FrequencySpectrum}
                  alt="Frequency spectrum for nominal and fault-cases"
                />
              </ul>
            </div>
            <div>
              <img
                src={StatisticalOperational}
                alt="Frequency spectrum for nominal and fault-cases"
              />
              <ul>
                <li>Cloud / privately hosted system</li>
                <li>Isolated data store for each customer</li>
                <li>Scalable ingestion and processing</li>
              </ul>
            </div>
          </div>
          <Fade name="Out" />
        </section>
        <div className="background-image background-4">
          <TriangleDown name="arrow-down-page-break" />
          <PageStart />
        </div>
        <section className="section" id="section-3">
          <Fade name="In" />
          <div className="section-content">
            <h1>Information for preventative maintenance</h1>
            <FlowGrid />
          </div>
          <Fade name="Out" />
        </section>
        <div className="background-image background-5">
          <TriangleDown name="arrow-down-page-break" />
          <PageStart />
        </div>
        <section className="section" id="section-4">
          <Fade name="In" />
          <div className="section-content">
            <h1>Reduced costs</h1>
            <p>
              Our approach of spotting problems in your assets early enables
              more cost effective scheduling of repairs.
            </p>
            <p>
              When monitoring a group of assets, maintainance decisions can be
              made depending on how many assets need attention, and if the issue
              can be temporeraly mitigated in a different way.
            </p>
            <p>
              Our continuous monitoring system enables defects to be found
              early, and inspection can be targeted rather than scheduled.
            </p>
            <p>
              For a low upfront cost, our IoT device can significantly lower the
              maintainance costs of your assets during its lifetime.
            </p>
            <div className="section-content">
              <h1>Get in Touch</h1>
              <p>Send Us an Email to: <a href="mailto:Contact@eleven-i.com">Contact@eleven-i.com</a></p>
            </div>
          </div>
          <Fade name="Out" />
        </section>
      </main>
    </div>
  );
};

export { Home };
